<template>
    <div class="home">

        <Head :hId="0" :recruit="false"></Head>
        <div class="main">
            <div class="banner">
                <el-carousel trigger="click">
                    <el-carousel-item v-for="(item, index) in bannerImg" :key="index">
                        <img :src="item.imgUrl" alt="" @click="jumpPage(index)" />
                        <img v-if="item.type == 6" @click="jumpPage(index)" class="play" src="@/assets/img/Index_JobSeeker/icon13.png" alt="" />
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="content">
                <div class="activity fs" v-if="activityImg.length > 0">
                    <div v-for="(item, index) in activityImg.slice(0, 3)" :key="index">
                        <a :href="'javascript:void(0);'" @click="jumpPage2(index)">
                            <img :src="item.imgUrl" alt="" />
                            <img v-if="item.type == 6" class="play" src="@/assets/img/Index_JobSeeker/icon13.png" alt="" />
                        </a>
                    </div>
                </div>
                <div class="tabStyle">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane v-for="(item, index) in tabTitle" :key="index" :label="item.title" :name="item.title">
                            <span slot="label">
                                <img v-if="index == 1" width="24px" height="24px" src="@/assets/img/Index_JobSeeker/hot.svg"
                                    alt="" />
                                <img v-if="index == 0" width="24px" height="24px"
                                    src="@/assets/img/Index_JobSeeker/Optimization.svg" alt="" />
                                <img v-if="index == 2" width="24px" height="24px" style="top: 5px;"
                                    src="@/assets/img/Index_JobSeeker/yx.png" alt="" /> {{ item.title }}</span>
                            <Position v-for="(item, index) in hotPostList" :key="index" @click.native="goToDetails(item.id)"
                                :val="item"></Position>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <div class="more" @click="showMore()">
                    <p>查看更多</p>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Head from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Position from "@/components/Position.vue";
import { bannerList, hotPost, recommendPost } from "@/api/index";

export default {
    name: "Index_JobSeeker",
    components: {
        Head,
        Footer,
        Position,
    },
    data() {
        return {
            tabTitle: [

                {
                    title: "优选推荐",
                    img: "Optimization.svg",
                },
                {
                    title: "热门岗位",
                    img: "hot.svg",
                },
                {
                    title: "意向岗位",
                    img: "yx.png",
                },
            ],
            activeName: "优选推荐",
            bannerImg: [],
            activityImg: [],
            hotPostList: [],
        };
    },
    mounted() {
        this.getBanner();
        this.getActivity();
        this.handleClick();
    },
    methods: {
        // tab切换
        handleClick() {
            if (this.activeName == "热门岗位") {
                hotPost({ pageSize: 21, pageNum: 1 }).then((res) => {
                    this.hotPostList = res.rows;
                });
            } else if (this.activeName == "优选推荐") {
                recommendPost({ pageSize: 21, pageNum: 1 }).then((res) => {
                    this.hotPostList = res.rows;
                });
            } else if (this.activeName == "意向岗位") {
                recommendPost({ pageSize: 21, pageNum: 1 }).then((res) => {
                    this.hotPostList = res.rows;
                });
            }
        },
        //  跳转详情页
        goToDetails(id) {
            this.$router.push({ path: "/indexDetails", query: { id: id } });
        },
        // 获取轮播数据
        getBanner() {
            bannerList({ position: 30, type: 0 }).then((res) => {
                this.bannerImg = res.data;
            });
        },
        // 获取活动图
        getActivity() {
            bannerList({ position: 31, type: 0 }).then((res) => {
                this.activityImg = res.data;
            });
        },
        // 跳转
        jumpPage(i) {
            if (this.bannerImg[i].type == 1) {
                // window.open(this.bannerImg[i].jumpUrl)
            } else if (this.bannerImg[i].type == 2) {
                window.open(this.bannerImg[i].jumpUrl)
            } else if (this.bannerImg[i].type == 3) {
                window.open('/newDetail/?id=' + this.bannerImg[i].jumpUrl)
            } else if (this.bannerImg[i].type == 4) {
                window.open('/indexDetails/?id=' + this.bannerImg[i].jumpUrl)
            } else if (this.bannerImg[i].type == 5) {
                window.open('/recruit/show?cs=1&id=' + this.bannerImg[i].jumpUrl)
            } else if (this.bannerImg[i].type == 6) {
                window.open('/viedo/index?videoUrl=' + this.bannerImg[i].videoUrl)
            }
        },
        // 跳转
        jumpPage2(i) {
            if (this.activityImg[i].type == 1) {
                // window.open(this.activityImg[i].jumpUrl)
            } else if (this.activityImg[i].type == 2) {
                window.open(this.activityImg[i].jumpUrl)
            } else if (this.activityImg[i].type == 3) {
                window.open('/newDetail/?id=' + this.activityImg[i].jumpUrl)
            } else if (this.activityImg[i].type == 4) {
                window.open('/indexDetails/?id=' + this.activityImg[i].jumpUrl)
            } else if (this.activityImg[i].type == 5) {
                window.open('/recruit/show?cs=1&id=' + this.activityImg[i].jumpUrl)
            } else if (this.activityImg[i].type == 6) {
                window.open('/viedo/index?videoUrl=' + this.activityImg[i].videoUrl)
            }
        },

        // 查看更多
        showMore() {
            this.$router.push({ path: "/searchJob" });
        }

    },
};
</script>
<style scoped>
.home {
    width: 100%;
    height: 100%;
}

.home .main {
    min-height: calc(100% - 278px);
}

.home .banner {
    height: 460px;
}

.home .banner img {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.home .main .banner /deep/ .el-carousel__container {
    height: 460px !important;
}

.home .banner .el-carousel {
    height: 100%;
}


.home .play {
    width: 58px !important;
    height: 58px !important;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -29px;
    margin-left: -29px;
    cursor: pointer !important;
    z-index: 9;
}

.home .activity {
    margin: 20px 0 40px 0;
    flex-wrap: wrap;
}

.home .activity:after {
    content: "";
    width: 386px;
    height: 0px;
}

.home .activity div {
    width: 386px;
    height: 217px;
    position: relative;
}

.home .activity div img {
    width: 100%;
    height: 100%;
}

.home .tabStyle {
    /*margin-bottom: 20px;*/
}

.home .tabStyle /deep/ .el-tabs__nav-scroll {
    background: linear-gradient(180deg,
            rgba(255, 255, 255, 0) 0%,
            #ffffff 45%,
            #ffffff 100%);
}

.home .tabStyle /deep/ .el-tabs__item {
    font-size: 22px;
    color: #666;
    padding: 0 100px 0 20px;
    height: 98px;
    line-height: 98px;
}

.home .tabStyle /deep/ .el-tabs__item.is-active {
    color: #0780e6;
}

.home .tabStyle /deep/ .el-tabs__item img {
    position: relative;
    top: 3px;
}

.home .tabStyle /deep/ .el-tabs__active-bar {
    left: 10px;
    height: 4px;
    color: #0780e6;
}

.home .tabStyle /deep/ .el-tabs__header {
    margin-bottom: 20px;
}

.home .tabStyle /deep/ .el-tab-pane {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.home .tabStyle /deep/ .el-tab-pane::after {
    display: block;
    content: "";
    width: 386px;
}

.home .more {
    margin-bottom: 60px;
}

.home .more p {
    width: 386px;
    height: 50px;
    background: #FFFFFF;
    border-radius: 0px 0px 0px 0px;
    border: 1px solid #0780E6;
    font-size: 20px;
    text-align: center;
    line-height: 50px;
    font-weight: 400;
    color: #0780E6;
    margin: 0 auto;
    cursor: pointer;
}
</style>
